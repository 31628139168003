/* eslint-disable no-undef */
import { ref, watch, computed, onBeforeMount } from "vue";

import { usePage, router } from '@inertiajs/vue3';

  //
  //  TODO: Vaatii ison refaktoroinnin!
  //

const $page = usePage();

export function useAnalytics() {
  if (
    import.meta.env.VITE_SEND_ANALYTICS !== "true" ||
    typeof window === "undefined"
  ) {
    return false;
  }

  // console.log("Using analytics");
  // Navigation events

  onBeforeMount(() => router.on("navigate", pageChange));

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events
  // https://developers.google.com/tag-platform/devguides/consent

  const gtag_pageview = function (
    analyticsEvent,
    pageComponentName,
    pageProps,
    pageUrl,
  ) {

    // Hox: lähtee automaattisesti, manuaalinen  määritys vaatii:
    // gtag('config', "G-84XPX2KYNL", { 'send_page_view': false });
    // Mutta stagessa lähti silti tuplana jostain syystä
    // gtag('event', 'page_view', { 'page_location': window.location.origin + pageUrl });

    // Facebook page view event
    if(window.fbq) {
      window.fbq('track', "PageView", {eventID: usePage().props.eventId});
    }


    //Tämä aina kun sivu latautuu

    if (analyticsEvent === "login") {
      contactGroupsEvent();
    } else if (analyticsEvent === "register") {
      if (import.meta.env.VITE_GTAG_ACCOUNT !== "") {
        gtag("event", "sign_up");
      }
    } else{
      contactGroupsEvent();
    }

    if (pageComponentName === "Product") {
      const groups = pageProps.breadcrumbs
        .map((crumb, index) => {
          if (index && index < pageProps.breadcrumbs.length - 1) {
            return crumb.name;
          }
        })
        .filter((n) => n);

      let item = {
        item_id: pageProps.product.id,
        item_name: pageProps.product.name,
        discount: parseFloat(
          pageProps.product.price_data.total_discount_amount_with_vat,
        ),
        price: parseFloat(
          pageProps.product.price_data.total_price_without_discount_with_vat,
        ),
        quantity: 1,
      };

      groups.forEach((group, index) => {
        item[index ? "item_category" + (index + 1) : "item_category"] = group;
      });

      if (import.meta.env.VITE_GTAG_ACCOUNT !== "") {
        gtag("event", "view_item", {
          currency: "EUR", // pageProps.cart.currency
          value: parseFloat(pageProps.product.price_data.price_with_vat),
          items: [item],
        });
      }

      if (import.meta.env.VITE_GTM_ACCOUNT !== "") {
        dataLayer.push({
          event: "view_item",
          ecommerce: {
            currency: "EUR", // pageProps.cart.currency
            value: parseFloat(pageProps.product.price_data.price_with_vat),
            items: [item],
          },
        });
      }

      // Custobar product view event
      if(window.cstbr) {
        if(window.cstbrConfig) {
          window.cstbrConfig.productId = pageProps.product.id;
        }
        window.cstbr.push({type: 'VIEW', product_id: pageProps.product.id});
      }

      // Facebook product page view event
      if(window.fbq) {
        window.fbq('track', 'ViewContent', {
          content_name: pageProps.product.name,
          content_category: (groups && groups.length) ? groups.join(" > ") : "",
          content_ids: [pageProps.product.id],
          content_type: 'product',
          value: parseFloat(pageProps.product.price_data.price_with_vat),
          currency: 'EUR',
        }, {eventID: usePage().props.eventId});
      }

    } else if(pageComponentName === "Cart") {

      if (import.meta.env.VITE_GTM_ACCOUNT !== "") {

        const cart = computed(() => {
          return $page.props?.cart ?? undefined;
        });

        const cartItems = computed(() => {
          if (cart.value) {
            return (cart.value?.cartitems ?? []).map(function (row, index) {
              return {
                item_id: row.products_id,
                item_name: row.name,
                currency: "EUR",
                discount: parseFloat(row.price_data.discount_amount_with_vat),
                price: parseFloat(row.price_data.price_without_discount_with_vat),
                index: index + 1,
                quantity: row.amount_cart,
              };
            });
          }
          return [];
        });

        const submittedShipping = ref(null);
        const activeShipping = computed(() => {
          return $page.props?.shippingMethods.find((shipping) => shipping.id == $page.props?.defaultShipping);
        });

        const submittedPayment = ref(null);
        const activePayment = computed(() => {
          let payment = activeShipping.value.paymentMethods.find((payment) => payment.id == $page.props?.defaultPayment);
          return payment;
        });

        const updateEvent = (evt, properties) => {
          var data = {
            currency: "EUR", // cart.value?.cart?.currency
            value: parseFloat(cart.value.display_price.total_price.total_price_with_vat ?? 0),
            items: cartItems.value,
          };
          data = Object.assign({}, data, properties);
          gtag("event", evt, data);
        };

        const gtag_add_info = router.on('start', (event) => {
          const url = (event.detail.visit.url ?? "").toString();
          // console.log(url);
          if (url.indexOf('/payment/') !== -1) {
            // console.log(`The route changed to ${url}`);
            var shipping = activeShipping.value;
            var payment = activePayment.value;
            // console.log(shipping, payment);
            if (shipping && shipping?.id != submittedShipping.value) {
              submittedShipping.value = shipping.id;
              updateEvent('add_shipping_info', {
                shipping_tier: shipping.name,
              });
            }
            if (payment && payment?.id != submittedPayment.value) {
              submittedPayment.value = payment.id;
              updateEvent('add_payment_info', {
                payment_type: payment.name,
              });
            }
          }
        });

        const removeCartListeners = router.on('finish', () => { // This prevents event duplication
          const component = $page?.component ?? null;
          if(component !== 'Cart') { // callback on Inertia EventListener removes them
            gtag_add_info();
            removeCartListeners();
          }
        });

        const event = {
          event: "begin_checkout",
          ecommerce: {
            currency: "EUR",
            value: parseFloat(cart.value?.display_price.total_price.total_price_with_vat ?? 0),
            items: cartItems.value,
          },
        };

        dataLayer.push(event);
      }

    } else if (pageComponentName === "ProductList") {
      // console.log('ProductList view', pageProps.products.data);

      const groups = pageProps.breadcrumbs
        .map((crumb, index) => {
          if (index) return crumb.name;
        })
        .filter((n) => n);

      const items = pageProps.products.data.map((product, index) => {
        let item = {
          item_id: product.id,
          item_name: product.name,
          currency: "EUR", // pageProps.cart.currency
          discount: parseFloat(product.price_data.discount_amount_with_vat),
          price: parseFloat(product.price_data.price_without_discount_with_vat),
          item_category: pageProps.name,
          item_list_name: pageProps.name,
          index: index + 1,
          quantity: 1,
        };

        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] =
            group;
        });

        return item;
      });


      if (import.meta.env.VITE_GTAG_ACCOUNT !== "") {
        gtag("event", "view_item_list", { items: items });
      }

      if (import.meta.env.VITE_GTM_ACCOUNT !== "") {
        dataLayer.push({
          event: "view_item_list",
          ecommerce: {
            items: [items],
          },
        });
      }
    } else if (pageComponentName === "Confirmation") {
      if (analyticsEvent === "confirmation") {
        const summary = pageProps.order.summary;

        if (!summary || !Array.isArray(summary.rows)) {
          return false;
        }

        const contact = $page.props.contact;
          let groups = "";
          if(contact && contact.groups && Array.isArray(contact.groups)) {
            groups = contact.groups.sort().join(',');
          }

        const items = summary.rows.map((row, index) => {
          return {
            item_id: row.products_id,
            item_variant: row.item_variant,
            item_name: row.name,
            currency: summary.currency,
            discount: parseFloat(row.price_data.discount_amount_with_vat),
            price: parseFloat(row.price_data.price_without_discount_with_vat),
            item_category: row.group_name,
            quantity: row.amount_cart,
          };
        });

        if (import.meta.env.VITE_GTAG_ACCOUNT !== "") {

          // Enhanced conversions
          const info = pageProps.order.info;
          if(info && isObject(info)) {
            gtag('set', 'user_data', info);
          }

          gtag("event", "purchase", {
            transaction_id: summary.order_number,
            value: summary.total,
            tax: summary.total_tax,
            shipping: summary.shipping,
            currency: summary.currency,
            items: items,
            user_id: contact?.number || 0,
            user_group: groups,
          });

          if(import.meta.env.VITE_GOOGLE_ADS_CONVERSION_ID && import.meta.env.VITE_GOOGLE_ADS_CONVERSION_LABEL) {
            gtag('event', 'conversion', {
              'send_to': import.meta.env.VITE_GOOGLE_ADS_CONVERSION_ID + '/' + import.meta.env.VITE_GOOGLE_ADS_CONVERSION_LABEL,
              'value': summary.total,
              'currency': summary.currency,
              'transaction_id': summary.order_number,
              });
          }
        }

        if (import.meta.env.VITE_GTM_ACCOUNT !== "") {
          /* const contact = $page.props.contact;
          let groups = "";
          if(contact && contact.groups && Array.isArray(contact.groups)) {
            groups = contact.groups.sort().join(',');
          } */
          dataLayer.push({
            event: "purchase",
            user_id: contact?.number || 0,
            user_group: groups,
            ecommerce: {
              transaction_id: summary.order_number,
              value: summary.total,
              tax: summary.total_tax,
              shipping: summary.shipping,
              currency: summary.currency,
              items: items,
            },
          });
        }

        // Facebook purchase event
        if(window.fbq) {
          const ids = items.map(item => item.item_id);
          window.fbq('track', 'Purchase', { content_ids: ids }, {currency: "EUR", value: summary.total}, {eventID: usePage().props.eventId});
        }

        // console.log({
        //   transaction_id: summary.order_number,
        //   value: summary.total,
        //   tax: summary.total_tax,
        //   shipping: summary.shipping,
        //   currency: summary.currency,
        //   items: items,
        // });
      }
    }
  };

  const pageChange = (event) => {
    // TODO: if cookies are not accepted -> return false

    // Vaikuttais että propsit ei tule mukana kun kutsuu resettiä ennen eventtiä:
    // https://developers.google.com/tag-platform/tag-manager/datalayer#custom_data_layer_methods
    if(window.dataLayer && Array.isArray(window.dataLayer) && window.dataLayer.length) {
      window.dataLayer.push(function() {
        this.reset();
      });
    }

    const analyticsEvent = event.detail.page.props.flash?.event ?? "";
    const pageComponentName = event.detail.page.component;
    const pageProps = $page.props;
    const pageUrl = event.detail.page.url;

    setTimeout(() => gtag_pageview(analyticsEvent, pageComponentName, pageProps, pageUrl), 150);
  };

}

watch(() => $page.props?.flash?.event, eventName => {

  const eventData = $page.props?.flash?.eventData;
  if(eventName === "cartAdd") {
    //Custobar
    if(window.cstbr) {
      eventData.items.map(item => window.cstbr.push({type: 'BASKET_ADD', product_id: item.item_id}));
    }
    //Facebook

    const ids = eventData.items.map((item) => item.item_id);

    if(window.fbq) {
      window.fbq('track', 'AddToCart', { content_ids: ids }, {eventID: usePage().props.eventId});//TODO CHECK THAT IDS GO IN HERE
    }
    newCartAction("add_to_cart", eventData);
  }
  else if(eventName === "cartRemove") {
    newCartAction("remove_from_cart", eventData);
  }
},{ deep: true });

function newCartAction(ga_method, eventData) {
  if(window.dataLayer && Array.isArray(window.dataLayer) && window.dataLayer.length) {
    window.dataLayer.push(function() {
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      this.reset();
    });
  }
  if (eventData.items.length) {
    if (gtagEnabled()) {
        gtag("event", ga_method, eventData);
    }
    if (gtmEnabled()) {
        const event = {
          event: ga_method,
          ecommerce: eventData,
        };
        dataLayer.push(event);
    }
  }
}

function isArray(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Array";
}

function isObject(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Object";
}

function gtagEnabled() {
  return import.meta.env.VITE_GTAG_ACCOUNT !== "" && typeof gtag === "function";
}

function gtmEnabled() {
  return import.meta.env.VITE_GTM_ACCOUNT !== "" && window.dataLayer;
}

function contactGroupsEvent(){
  if (import.meta.env.VITE_GTM_ACCOUNT !== "") {
    const contact = $page.props.contact;
    let groups = "";
    if(contact && contact.groups && Array.isArray(contact.groups)) {
      groups = contact.groups.sort().join(',');
    }
    dataLayer.push({
      'event': 'user_info',
      'user_id': contact?.number,
      'user_group': groups,
    });
  }
}